import React from "react";
import SliderImageDetails from "./SliderImageDetails";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const sliderImageDetailsData = {
  title: "👰🤵 सुतार झाडे समाजाचे वधू-वर नोंदणी सुरू झाली आहे! 🎉",
  subTitle:
    "यावर्षी आपल्या जीवनाच्या साथीदाराचा शोध घेण्यासाठी आपली नोंदणी करा",
  note: "आणि सुखी वैवाहिक जीवनाची सुरुवात करा.",
  button: {
    href: "/registration",
    title: "✨ आजच नोंदणी करा! ✨",
  },
  subBanner: {
    title: "II श्री प्रभु विश्वकर्मा प्रसन्न् II",
    subTitle: process.env.REACT_APP_WEBSITE_NAME,
    note: "( रजि. नं. : महा / 1290 / अम. / 85 एफ - 1414 / अम. 85 )",
  },
};

const Banner = ({ image }) => {
  const location = useLocation();
  if (location.pathname !== "/") {
    return null;
  }

  return (
    <section style={{ position: "relative" }}>
      <LazyLoadImage
        alt=""
        style={{ height: "75vh", width: "100vw" }}
        className="h-full w-full object-cover"
        src={image}
      />
      <SliderImageDetails {...sliderImageDetailsData} />
      <div className="md:px-6 md:py-4 bg-secondary text-center font-bold py-2 border border-gray-300">
        <p className="p-0 !text-red-700 text-sm md:text-lg m-auto">
          {sliderImageDetailsData.subBanner.title}
        </p>
        <p className="p-0 !text-primary text-sm md:text-md m-auto">
          {sliderImageDetailsData.subBanner.subTitle}
        </p>
        <p className="p-0 !text-primary text-sm md:text-md m-auto">
          {sliderImageDetailsData.subBanner.note}
        </p>
      </div>
    </section>
  );
};

export default Banner;
