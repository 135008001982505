import { Link } from "react-router-dom";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../Loading";
import AlertBox from "./AlertBox";
import useMarkedPaymentStatus from "./useMarkedPaymentStatus";

const PaymentStatus = ({ alwaysShow = false }) => {
  const { isLoading, data, refetch } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/razorpay_payment_logs`
  );

  useMarkedPaymentStatus(refetch);

  if (isLoading || !data) {
    return <Loading />;
  }

  if (!data?.[0]?.log) {
    return (
      <AlertBox
        color="red"
        title="Alert"
        message={
          <>
            {
              "Payment was not successful due some failure. Please reach out to the contact person for more information."
            }
            <br />
            <Link to="/payment" className="font-bold underline">
              Re-try payment
            </Link>
          </>
        }
      />
    );
  }

  if (!JSON.parse(data?.[0]?.log)?.razorpay_payment_id) {
    return (
      <AlertBox
        color="red"
        title="Alert"
        message={
          <>
            {
              "Payment was not successful due some failure. Please reach out to the contact person for more information."
            }
            <br />
            {data?.[0]?.log}
          </>
        }
      />
    );
  }

  if (alwaysShow) {
    return (
      <AlertBox
        color="green"
        title="Success"
        message={`Payment was successful with the payment id - ${
          data?.[0]?.log && JSON.parse(data?.[0]?.log)?.razorpay_payment_id
        }.`}
      />
    );
  }

  return null;
};

export default PaymentStatus;
