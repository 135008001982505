import React from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuthDelete } from "../utils/hooks";
import errorAlert from "../utils/errorAlert";
import Loading from "./Loading";

const Form = ({ onClose, form: { deleteUrl, onSubmit } }) => {
  const { makeRequest, isLoading } = useAuthDelete(
    `${process.env.REACT_APP_API_BASE_URI}/api/${deleteUrl}`,
    (data) => {
      if (data.success) {
        onSubmit();
      } else {
        errorAlert(data);
      }
    }
  );

  return (
    <>
      {isLoading && <Loading />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          makeRequest();
        }}
        id="my-form"
        className="flex flex-col gap-4 md:mt-2"
      >
        <div className="m-auto">
          <Button className="mx-2 bg-primary" onClick={onClose}>
            {"No"}
          </Button>
          <Button className="mx-2" type="submit">
            {"Yes"}
          </Button>
        </div>
      </form>
    </>
  );
};

const DeleteConfirmationModal = ({
  renderButton,
  title,
  subTitle = "Do you really want to delete this?",
  form,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      {renderButton &&
        renderButton({
          open,
          handleOpen,
        })}
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="p-4"
        dismiss={{ enabled: false }}
      >
        <DialogHeader className="relative m-0 block">
          {title && (
            <Typography variant="h4" color="blue-gray">
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography className="mt-1 font-normal text-gray-600">
              {subTitle}
            </Typography>
          )}
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <XMarkIcon className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6">
          {open && <Form onClose={handleOpen} form={form} />}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationModal;
