import React from "react";
import NewAndUpdates from "../../components/NewAndUpdates";
import AboutUs from "../../components/AboutUs";

const HomePage = () => {
  return (
    <>
      <AboutUs />
      <NewAndUpdates />
    </>
  );
};

export default HomePage;
