import { useEffect, useState } from "react";

const useHandleNav = () => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return [openNav, setOpenNav];
};

export default useHandleNav;
