import { errorToast } from "./toast";

const errorAlert = (data) =>
  errorToast(
    data.error.message ||
      data.error ||
      "Something went wrong, try after some time!"
  );

export default errorAlert;
