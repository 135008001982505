import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/Loading";
import { useFetch, useAuthPost } from "../../utils/hooks";
import { warningToast } from "../../utils/toast";
import { isEmpty } from "lodash";
import { useAuth } from "../../utils/AuthProvider";
import errorAlert from "../../utils/errorAlert";
import useLogout from "../../utils/useLogout";
import useRenderInputs from "../../utils/useRenderInputs";

const defaultValues = {
  image: "",
  name: "",
  fatherName: "",
  motherName: "",
  gender: "",
  maritalStatus: "",
  dob: "",
  age: "",
  height: "",
  weight: "",
  education: "",
  working: "",
  placeOfWork: "",
  phone: "",
  address: "",
  mamKul: "",
  mamKulAddress: "",
  state: "Maharashtra",
  email: "",
  password: "",
  confirm_password: "",
  birthTime: "",
  birthPlace: "",
  bloodGroup: "",
  annualIncome: "",
  educationInfo: "",
};

export const labels = {
  image: "Upload Profile Photo (प्रोफाइल फोटो अपलोड करा)*",
  name: "Full Name (पूर्ण नाव)*",
  fatherName: "Father's Name (वडिलांचे नाव)*",
  motherName: "Mother's Name (आईचे नाव)*",
  gender: "Gender (लिंग)*",
  maritalStatus: "वैवाहिक स्थिती*",
  dob: "Date of Birth (जन्मतारीख)*",
  age: "Age (वय)*",
  height: "Height in Feet (उंची)*",
  weight: "Weight in Kg (वजन)",
  education: "Education Qualification (शैक्षणिक पात्रता)*",
  educationInfo: "Education Information (शैक्षणिक माहिती)*",
  working: "Working in (व्यवसाय)*",
  placeOfWork: "Work & Place of Work (काम व कामाचे ठिकाण)*",
  phone: "Mobile No (संपर्कासाठी मोबाईल नंबर)*",
  address: "Full Address (पूर्ण पत्ता)*",
  mamKul: "MamKul (मामकुळ)*",
  mamKulAddress: "MamKul Address (मामकुळ पत्ता)",
  state: "State (राज्य)*",
  email: "Email (ईमेल)*",
  password: "Password (पासवर्ड)*",
  confirm_password: "Confirm Password (पासवर्डची पुष्टी करा)*",
  birthTime: "Birth Time (जन्मवेळ)",
  birthPlace: "Birth Place (जन्मस्थान)",
  bloodGroup: "Blood Group (रक्त गट)",
  annualIncome: "Annual Income (वार्षिक उत्पन्न)",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const userSchemaWithoutPassword = yup.object().shape({
  image: yup.string().required().label(labels["image"]),
  name: yup.string().required().label(labels["name"]),
  fatherName: yup.string().required().label(labels["fatherName"]),
  motherName: yup.string().required().label(labels["motherName"]),
  age: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? 0 : value))
    .required()
    .positive()
    .integer()
    .min(18)
    .max(100)
    .label(labels["age"]),
  email: yup.string().email().required().label(labels["email"]),
  phone: yup
    .string()
    .matches(phoneRegExp, `${labels["phone"]} is not valid`)
    .label(labels["phone"]),
  mamKul: yup.string().required().label(labels["mamKul"]),
  mamKulAddress: yup.string().nullable().label(labels["mamKulAddress"]),
  placeOfWork: yup.string().required().label(labels["placeOfWork"]),
  dob: yup.string().required().label(labels["dob"]),
  state: yup.string().required().label(labels["state"]),
  gender: yup.string().required().label(labels["gender"]),
  maritalStatus: yup.string().required().label(labels["maritalStatus"]),
  address: yup.string().required().label(labels["address"]),
  height: yup.string().required().label(labels["height"]),
  weight: yup.string().nullable().label(labels["weight"]),
  education: yup.string().required().label(labels["education"]),
  educationInfo: yup.string().required().label(labels["educationInfo"]),
  working: yup.string().required().label(labels["working"]),
  birthTime: yup.string().nullable().label(labels["birthTime"]),
  birthPlace: yup.string().nullable().label(labels["birthPlace"]),
  bloodGroup: yup.string().nullable().label(labels["bloodGroup"]),
  annualIncome: yup.string().nullable().label(labels["annualIncome"]),
});

export const userSchema = userSchemaWithoutPassword.shape({
  password: yup
    .string()
    .required()
    .min(8, `${labels["password"]} is too short - should be 8 chars minimum.`)
    .matches(
      /[a-zA-Z]/,
      `${labels["password"]} can only contain Latin letters.`
    )
    .label(labels["password"]),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      `${labels["confirm_password"]} must match`
    )
    .required()
    .label(labels["confirm_password"]),
});

export const getInput = (id, type, additionalProps) => ({
  id,
  type,
  ...additionalProps,
});

export const getTextInput = (id, additionalProps) =>
  getInput(id, "text", additionalProps);
export const getTextAreaInput = (id) => getInput(id, "textarea");
export const getPasswordInput = (id) => getInput(id, "password");
export const getImageInput = (id) => getInput(id, "image");
export const getSelectInput = (data, id) =>
  getInput(id, "select", { options: data?.[id] || [] });

export const useFormConfigurations = (hidePassword) => {
  const { data } = useFetch(`/data.json`);

  if (!data) {
    return {
      sections: [],
    };
  }

  return {
    sections: [
      {
        title: "",
        inputGroups: [[getImageInput("image")]],
      },
      {
        title: "Personal details (वैयक्तिक तपशील)",
        inputGroups: [
          [getTextInput("name")],
          [getTextInput("fatherName"), getTextInput("motherName")],
          [
            getSelectInput(data, "gender"),
            getSelectInput(data, "maritalStatus"),
          ],
          [
            getInput("dob", "datepicker"),
            getTextInput("age", { readOnly: true }),
          ],
          [getTextInput("birthPlace"), getInput("birthTime", "timepicker")],
          [getTextInput("height"), getTextInput("weight")],
          [getSelectInput(data, "bloodGroup")],
        ],
      },
      {
        title: "Education and work details (शिक्षण आणि कामाचा तपशील)",
        inputGroups: [
          [getSelectInput(data, "education"), getTextInput("educationInfo")],
          [getSelectInput(data, "working"), getTextInput("placeOfWork")],
          [getSelectInput(data, "annualIncome")],
        ],
      },
      {
        title: "Contact Details (संपर्क तपशील)",
        inputGroups: [
          [getTextInput("phone")],
          [getTextInput("address")],
          [getSelectInput(data, "state")],
          [getTextInput("mamKul")],
          [getTextInput("mamKulAddress")],
        ],
      },
      {
        title: "Login Detials (लॉगिन तपशील)",
        inputGroups: [
          [getTextInput("email", { readOnly: hidePassword })],
          ...(hidePassword
            ? []
            : [
                [getPasswordInput("password")],
                [getPasswordInput("confirm_password")],
              ]),
        ],
      },
    ],
  };
};

const Registration = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const { logout } = useLogout(true);

  const formData = useForm({
    defaultValues,
    resolver: yupResolver(userSchema),
  });

  const { makeRequest, isLoading } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate/register`,
    (data) => {
      if (data.success) {
        setToken(data.token);
        warningToast("Please pay now to complete the registration!");
        navigate("/payment", { replace: true });
      } else if (data.status === 409) {
        formData(data.message.errors);
      } else {
        errorAlert(data);
      }
    }
  );

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formConfigurations = useFormConfigurations();
  const renderInput = useRenderInputs(labels, formData);

  return (
    <div className="mx-auto max-w-screen-lg">
      {isLoading && <Loading />}
      <Card
        shadow={false}
        className="md:px-24 md:py-14 py-8 border border-gray-300"
      >
        <CardHeader shadow={false} floated={false} className="text-center">
          <h1 className="mb-2 text-4xl" style={{ lineHeight: "3.5rem" }}>
            {"Registration (नोंदणी)"}
          </h1>
          <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-sm">
            {"Groom and Bride Information Form"}
          </p>
          <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-sm">
            {"उपवर उपवधूंच्या माहितीसाठी चा फॉर्म"}
          </p>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={formData.handleSubmit(makeRequest)}
            id="my-form"
            className="flex flex-col gap-4 md:mt-2"
          >
            {formConfigurations.sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-4 font-medium "
                >
                  {section.title}
                </Typography>
                {section.inputGroups.map((inputGroup, igIndex) => (
                  <div
                    key={igIndex}
                    className={`grid md:grid-cols-${inputGroup.length} gap-2`}
                  >
                    {inputGroup.map((inputConfig, icIndex) => (
                      <React.Fragment key={icIndex}>
                        {renderInput(inputConfig)}
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            <div>
              <Button type="submit" size="lg" className="bg-primary" fullWidth>
                {"Register (नोंदणी करा)"}
              </Button>
              {!isEmpty(formData?.formState?.errors) && (
                <p className="flex items-center justify-center gap-1 font-normal text-md text-red-500">
                  {
                    "To Register, Please resolve the all validation errors by providing valid inputs"
                  }
                </p>
              )}
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Registration;
