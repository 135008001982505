import { useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth, userRole } from "../../utils/AuthProvider";
import PaymentStatus from "../../components/PaymentStatus";
import { useAuthFetch, useFetch } from "../../utils/hooks";
import {
  compact,
  filter,
  find,
  findIndex,
  flatten,
  includes,
  isEmpty,
  isFunction,
  keys,
  map,
  reduce,
  reverse,
  size,
  sortBy,
  toLower,
  toNumber,
  uniqBy,
} from "lodash";
import Loading from "../../components/Loading";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import NoDataFound from "../../components/NoDataFound";
import {
  ArrowDownward,
  ArrowUpward,
  FilterAltOff,
  Filter,
  KeyboardArrowDown,
  Minimize,
  Add,
} from "@mui/icons-material";
import { getTime } from "date-fns/getTime";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";
import DownloadXls from "../../components/DownloadXls";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

const ordering = {
  true: "asc",
  false: "desc",
};

const sortOptions = [
  {
    name: "Newest",
    keyName: "created_at",
    ordering: ordering.ascending,
    current: true,
  },
  { name: "Age", keyName: "age", ordering: ordering.ascending, current: false },
  {
    name: "Income",
    keyName: "annualIncome",
    ordering: ordering.ascending,
    current: false,
  },
];

const sortValueGenerator = {
  created_at: (val) => getTime(new Date(val)),
  age: (val) => toNumber(val),
  annualIncome: (val, defaultFilters) =>
    findIndex(
      find(defaultFilters, (x) => x.id === "annualIncome").options,
      (x) => x.value === val
    ),
};

const getSortingValue = (data, keyName, defaultFilters) => {
  return sortValueGenerator[keyName](data[keyName], defaultFilters);
};

const labelsForCandidate = {
  maritalStatus: "वैवाहिक स्थिती",
  education: "Education Qualification (शैक्षणिक पात्रता)",
  working: "Working in (व्यवसाय)",
  bloodGroup: "Blood Group (रक्त गट)",
  annualIncome: "Annual Income (वार्षिक उत्पन्न)",
};

const labelsForAdmin = {
  gender: "Gender (लिंग)",
  paymentStatusId: "Payment Status",
  ...labelsForCandidate,
};

const useSorting = () => {
  const [sorting, setSorting] = useState(sortOptions);

  const resetToDefaultSorting = () => {
    setSorting(sortOptions);
  };

  const updateSorting = (name, ordering) => {
    setSorting(
      map(sorting, (x) => {
        if (x.name === name) {
          return {
            ...x,
            ordering,
            current: true,
          };
        }

        return { ...x, current: false };
      })
    );
  };

  return {
    sorting,
    updateSorting,
    resetToDefaultSorting,
  };
};

const useFilters = () => {
  const [filters, setFilters] = useState([]);
  const defaultFiltersRef = useRef([]);
  const { role } = useAuth();
  const resetToDefaultFiltering = () => {
    setFilters(defaultFiltersRef.current);
  };
  const labels = role === userRole.admin ? labelsForAdmin : labelsForCandidate;
  useFetch(`/data.json`, (data) => {
    const formattedData = {
      ...data,
      paymentStatusId: [
        {
          label: "Paid",
          value: (val) => val.paymentStatusId !== null,
        },
        {
          label: "Unpaid",
          value: (val) => val.paymentStatusId === null,
        },
      ],
    };
    defaultFiltersRef.current = filter(
      map(keys(formattedData), (key) => {
        if (!labels[key]) {
          return;
        }

        return {
          id: key,
          name: labels[key],
          options: map(formattedData[key], (opt) => {
            return {
              ...opt,
              checked: false,
            };
          }),
        };
      }),
      (x) => !!x
    );

    resetToDefaultFiltering();
  });

  const updateFilters = (section, key, checked) => {
    setFilters(
      map(filters, (x) => {
        if (x.id === section) {
          return {
            ...x,
            options: map(x.options, (opt) => {
              if (opt.label === key) {
                return {
                  ...opt,
                  checked,
                };
              }
              return opt;
            }),
          };
        }

        return x;
      })
    );
  };

  return {
    filters,
    updateFilters,
    resetToDefaultFiltering,
    defaultFilters: defaultFiltersRef.current,
  };
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SearchInput = ({ onSearch, searchText }) => {
  return (
    <div className="flex-1">
      <Input
        size="sm"
        className="w-full"
        placeholder="Full Name"
        label="Search Candidate Names"
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
};

const Sorting = ({ sorting, updateSorting }) => {
  return (
    <Menu as="div" className="relative inline-block">
      <MenuButton
        as={Button}
        variant="outlined"
        size="sm"
        className="inline-flex items-center mx-2"
      >
        Sort
        <KeyboardArrowDown
          aria-hidden="true"
          className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
        />
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {sorting.map((option) => (
            <MenuItem key={option.name}>
              <Button
                variant="text"
                className={classNames(
                  option.current
                    ? "font-medium text-gray-900"
                    : "text-gray-500",
                  "w-full text-left block px-4 py-2 text-sm data-[focus]:bg-gray-100 data-[focus]:outline-none"
                )}
                onClick={() => updateSorting(option.name, !option.ordering)}
              >
                <span className="flex justify-between">
                  {option.name}
                  <span className="ml-6 flex items-center">
                    {option.ordering ? (
                      <ArrowUpward aria-hidden="true" className="size-5" />
                    ) : (
                      <ArrowDownward aria-hidden="true" className="size-5" />
                    )}
                  </span>
                </span>
              </Button>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
};

const DashboardPageHeading = ({
  sorting,
  updateSorting,
  sortedData,
  appliedFilters,
  resetToDefaultFiltering,
  resetToDefaultSorting,
  setMobileFiltersOpen,
  onSearch,
  searchText,
}) => {
  const { role } = useAuth();

  return (
    <div className="flex items-center justify-end md:justify-between border-b border-gray-200 py-6">
      <Typography variant="h1" className="hidden md:block">
        Candidates
      </Typography>
      <div className="flex items-center flex-col justify-end md:flex-row w-full">
        <div className="w-full md:w-auto m-2 px-2">
          <SearchInput onSearch={onSearch} searchText={searchText} />
        </div>
        <div className="w-full md:w-auto flex md:block justify-end items-center">
          {role === userRole.admin && <DownloadXls data={sortedData} />}
          <Button
            variant="outlined"
            disabled={isEmpty(appliedFilters) && !searchText}
            onClick={() => {
              resetToDefaultFiltering();
              resetToDefaultSorting();
              onSearch("");
            }}
            size="sm"
            className="inline-flex items-center mx-2"
          >
            <FilterAltOff />
            <span className="hidden md:inline-block">Reset Filters</span>
          </Button>
          <Button
            variant="outlined"
            onClick={() => setMobileFiltersOpen(true)}
            size="sm"
            className="inline-flex items-center mx-2 lg:hidden"
          >
            <Filter />
            <span className="hidden md:inline-block">Filters</span>
          </Button>
          <Sorting sorting={sorting} updateSorting={updateSorting} />
        </div>
      </div>
    </div>
  );
};

const DashboardPage = ({ data }) => {
  const [searchText, setSearchText] = useState("");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { role } = useAuth();
  const { filters, defaultFilters, updateFilters, resetToDefaultFiltering } =
    useFilters();
  const { sorting, updateSorting, resetToDefaultSorting } = useSorting();

  const appliedFilters = flatten(
    compact(
      map(filters, (x) => {
        const options = compact(
          map(x.options, (opt) => {
            if (opt.checked) {
              return isFunction(opt.value) ? opt.value : { [x.id]: opt.value };
            }
            return;
          })
        );
        if (isEmpty(options)) {
          return;
        }
        return {
          ...x,
          options,
        };
      })
    )
  );

  const searchResult = useMemo(
    () =>
      searchText
        ? filter(data, (x) => includes(toLower(x.name), toLower(searchText)))
        : data,
    [data, searchText]
  );

  const filteredData = useMemo(
    () =>
      isEmpty(appliedFilters)
        ? searchResult
        : reduce(
            appliedFilters,
            (result, x) =>
              uniqBy(
                flatten(map(x.options, (opt) => filter(result, opt))),
                "id"
              ),
            searchResult
          ),
    [appliedFilters, searchResult]
  );

  const appliedSortings = find(sorting, (x) => x.current);

  const sortedData = useMemo(() => {
    const result = sortBy(filteredData, (x) =>
      getSortingValue(x, appliedSortings.keyName, defaultFilters)
    );
    return appliedSortings.ordering ? result : reverse(result);
  }, [
    appliedSortings.keyName,
    appliedSortings.ordering,
    defaultFilters,
    filteredData,
  ]);

  // console.log("update", appliedFilters, filteredData, sortedData);

  console.log(
    appliedSortings,
    map(sortedData, (x) => x.id)
  );

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
          className="relative z-40 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex size-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex size-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                {filters.map(
                  (section) =>
                    section && (
                      <Disclosure
                        key={section.id}
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                        defaultOpen={true}
                      >
                        <h3 className="-mx-2 -my-3 flow-root text-xs md:text-md">
                          <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              <Add
                                aria-hidden="true"
                                className="size-5 group-data-[open]:hidden"
                              />
                              <Minimize
                                aria-hidden="true"
                                className="size-5 [.group:not([data-open])_&]:hidden"
                              />
                            </span>
                          </DisclosureButton>
                        </h3>
                        <DisclosurePanel className="pt-6">
                          <div className="space-y-6">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  type="checkbox"
                                  checked={option.checked}
                                  onChange={(e) =>
                                    updateFilters(
                                      section.id,
                                      option.label,
                                      e.target.checked
                                    )
                                  }
                                  className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className="ml-3 min-w-0 flex-1 text-gray-500"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </DisclosurePanel>
                      </Disclosure>
                    )
                )}
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
          <div className="my-6">
            {role === userRole.candidate && <PaymentStatus alwaysShow />}
          </div>
          <DashboardPageHeading
            sorting={sorting}
            updateSorting={updateSorting}
            sortedData={sortedData}
            appliedFilters={appliedFilters}
            resetToDefaultFiltering={resetToDefaultFiltering}
            resetToDefaultSorting={resetToDefaultSorting}
            setMobileFiltersOpen={setMobileFiltersOpen}
            onSearch={setSearchText}
            searchText={searchText}
          />

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                <Typography variant="h3">Filters</Typography>
                {filters.map(
                  (section) =>
                    section && (
                      <Disclosure
                        key={section.id}
                        as="div"
                        className="border-b border-gray-200 py-6"
                        defaultOpen={true}
                      >
                        <h3 className="-my-3 flow-root">
                          <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              <Add
                                aria-hidden="true"
                                className="size-5 group-data-[open]:hidden"
                              />
                              <Minimize
                                aria-hidden="true"
                                className="size-5 [.group:not([data-open])_&]:hidden"
                              />
                            </span>
                          </DisclosureButton>
                        </h3>
                        <DisclosurePanel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  type="checkbox"
                                  checked={option.checked}
                                  onChange={(e) =>
                                    updateFilters(
                                      section.id,
                                      option.label,
                                      e.target.checked
                                    )
                                  }
                                  className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </DisclosurePanel>
                      </Disclosure>
                    )
                )}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-right py-2"
                >
                  {`Showing result, ${size(sortedData)} of ${size(data)}`}
                </Typography>
                <LazyLoadComponent>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry gutter="1rem">
                      {map(sortedData, (candidate) => (
                        <Card key={candidate.id} className="border-2 shadow-xl">
                          <CardHeader floated={false} color="blue-gray">
                            <LazyLoadImage
                              src={candidate.image}
                              className="w-full"
                              alt="ui/ux review check"
                            />
                            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
                            <IconButton
                              size="sm"
                              color="red"
                              variant="text"
                              className="hidden !absolute top-4 right-4 rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-6 w-6"
                              >
                                <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                              </svg>
                            </IconButton>
                          </CardHeader>
                          <CardBody className="text-center px-4 py-2">
                            <div className="mb-3 flex items-center justify-between">
                              <Typography
                                variant="h5"
                                color="blue-gray"
                                className="font-medium"
                              >
                                {candidate.name}
                              </Typography>
                            </div>
                            <Typography
                              color="gray"
                              className="text-xs md:text-sm font-medium"
                            >
                              {`${candidate.educationInfo}`}
                            </Typography>
                            <div className="flex flex-wrap justify-center mt-4 mx-6">
                              <div className="text-xs mr-2 my-1 uppercase tracking-wider border px-2 text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-indigo-100 cursor-default">
                                {`Age: ${candidate.age}`}
                              </div>
                              <div className="text-xs mr-2 my-1 uppercase tracking-wider border px-2 text-green-600 border-green-600 hover:bg-green-600 hover:text-green-100 cursor-default">
                                {candidate.maritalStatus}
                              </div>
                            </div>
                          </CardBody>
                          <CardFooter className="pt-3">
                            <Link to={`/candidate/${candidate.id}`}>
                              <Button size="lg" variant="" fullWidth={true}>
                                View Full Profile
                              </Button>
                            </Link>
                          </CardFooter>
                        </Card>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </LazyLoadComponent>
                {/* Your content */}
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidates/filtered`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return <DashboardPage data={data} />;
};

export default Dashboard;
