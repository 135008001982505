import { FileDownload } from "@mui/icons-material";
import { CSVLink } from "react-csv";

const DownloadXls = ({ data }) => {
  return (
    <CSVLink
      className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg border border-gray-900 text-gray-900 hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] inline-flex items-center mx-2"
      data={data}
      filename={process.env.REACT_APP_WEBSITE_NAME}
      target="_blank"
    >
      <FileDownload />
      <span className="hidden md:inline-block">Download</span>
    </CSVLink>
  );
};

export default DownloadXls;
