import React from "react";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const SliderImageDetails = ({ title, subTitle, note, button }) => (
  <div className="w-full">
    <div className="absolute left-0 right-0 bottom-32 md:bottom:64 mx-auto w-3/4 md:w-2/4">
      {title && (
        <h6>
          <span
            style={{
              backgroundColor: "#fef8f5",
            }}
            className="p-2 rounded-lg text-md md:text-2xl text-bold"
          >
            {title}
          </span>
        </h6>
      )}
      {subTitle && (
        <h4>
          <span
            style={{
              backgroundColor: "#fef8f5",
            }}
            className="p-2 rounded-lg text-sm md:text-lg"
          >
            {subTitle}
          </span>
        </h4>
      )}
      {note && (
        <h4>
          <span
            style={{
              backgroundColor: "#fef8f5",
            }}
            className="p-2 rounded-lg text-sm md:text-lg"
          >
            {note}
          </span>
        </h4>
      )}
      {button && (
        <Link to={button.href}>
          <Button size="lg" className="bg-primary mt-4" variant="filled">
            {button.title}
          </Button>
        </Link>
      )}
    </div>
  </div>
);

export default SliderImageDetails;
