import { Button, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import useGenerateNavList from "./useGenerateNavList";
import NavMenu from "./NavMenu";

const NavList = () => {
  const navList = useGenerateNavList();

  return (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {navList.map(
        (list, listIndex) =>
          list.isVisible &&
          (list.items ? (
            <NavMenu key={listIndex} title={list.title} items={list.items} />
          ) : (
            <Typography
              key={listIndex}
              as="li"
              variant="small"
              className="text-secondary font-bold"
            >
              {list.onClick ? (
                <Button
                  className="flex items-center p-2 text-secondary !normal-case text-md !bg-transparent"
                  variant="text"
                  onClick={list.onClick}
                >
                  {list.title}
                </Button>
              ) : (
                <Link to={list.linkTo} className="flex items-center">
                  <Button
                    className="flex items-center p-2 text-secondary !normal-case text-md !bg-transparent"
                    variant="text"
                    onClick={list.onClick}
                  >
                    {list.title}
                  </Button>
                </Link>
              )}
            </Typography>
          ))
      )}
    </ul>
  );
};

export default NavList;
