import React from "react";
import { Carousel } from "@material-tailwind/react";
import PrevArrow from "./PrevArrow";
import NextArrow from "./NextArrow";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SliderImages = ({ images, className, imageSize }) => {
  return (
    <div
      style={{ position: "relative" }}
      className={`${className} slider-container`}
    >
      <Carousel
        autoplay
        autoplayDelay={10000}
        loop
        className="overflow-hidden"
        prevArrow={PrevArrow}
        nextArrow={NextArrow}
      >
        {images.map((img, imgIndex) => (
          <div key={imgIndex}>
            <LazyLoadImage
              alt=""
              style={imageSize}
              className="h-full w-full object-cover"
              src={require(`../../assets/${img}`)}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderImages;
