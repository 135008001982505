import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Banner from "../components/Banner";
import Header from "./Header";
import ScrollToTop from "../components/ScrollToTop";

const Main = () => {
  return (
    <>
      <Header />
      <Banner image={require("../assets/2.jpg")} />
      <main id="main" className="flex-1 bg-secondary bg-opacity-20">
        <div className="mx-auto flex-1 max-w-screen-xl p-6">
          <Outlet />
        </div>
      </main>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Main;
