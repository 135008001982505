import {
  Card,
  Typography,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
  IconButton,
  Avatar,
} from "@material-tailwind/react";
import {
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import FormModal from "../../components/FormModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getImageInput, getTextInput } from "../candidate/Registration";
import { isEmpty } from "lodash";
import NoDataFound from "../../components/NoDataFound";
import { userRole } from "../../utils/AuthProvider";
import usePaginated from "../../utils/usePaginated";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const TABLE_HEAD = ["समितीचे नाव", "समिती पदनाम", "समितीचे संपर्क क्रमांक"];

const defaultValues = {
  url: "",
  name: "",
  designation: "",
  contact: "",
};

const labels = {
  url: "Upload Photo (फोटो अपलोड करा)*",
  name: "समितीचे नाव*",
  designation: "समिती पदनाम*",
  contact: "समितीचे संपर्क क्रमांक*",
};

const schema = yup.object().shape({
  url: yup.string().required().label(labels["url"]),
  name: yup.string().required().label(labels["name"]),
  designation: yup.string().required().label(labels["designation"]),
  contact: yup.string().required().label(labels["contact"]),
});

function ListOfCommitteeInformation({ list, refetch, allowEdit }) {
  const { currentPage, totalPages, data, next, prev } = usePaginated(list, 10);

  const fieldConfigurations = {
    sections: [
      {
        title: "",
        inputGroups: [
          [getImageInput("url")],
          [getTextInput("name")],
          [getTextInput("designation")],
          [getTextInput("contact")],
        ],
      },
    ],
  };

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              {"Committee Information (महासंघ कार्यकारणी)"}
            </Typography>
          </div>
          {allowEdit && (
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <FormModal
                title="Add New Committee Member"
                submitButtonTitle="Add"
                form={{
                  defaultValues,
                  resolver: yupResolver(schema),
                  fieldConfigurations,
                  postUrl: "committees",
                  labels,
                  onSubmit: refetch,
                }}
                renderButton={({ handleOpen }) => (
                  <Button
                    className="flex items-center gap-3"
                    onClick={handleOpen}
                    size="sm"
                  >
                    <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
                    {"Add New Committee Member"}
                  </Button>
                )}
              />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody className="overflow-x-scroll md:overflow-x-auto px-0">
        {isEmpty(data) ? (
          <NoDataFound />
        ) : (
          <table className="w-full min-w-max md:table-fixed text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-bold leading-none"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
                {allowEdit && (
                  <>
                    <th
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      style={{ width: 80 }}
                    ></th>
                    <th
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      style={{ width: 80 }}
                    ></th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((committee, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4 text-wrap max-w-72"
                  : "p-4 border-b border-blue-gray-50 text-wrap max-w-72";

                return (
                  <tr key={index}>
                    <td className={`max-w-64 ${classes}`}>
                      <div className="flex md:items-center flex-col md:flex-row">
                        <Avatar
                          src={committee.url}
                          alt="avatar"
                          variant="rounded"
                          className="mr-4"
                        />
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {committee.name}
                        </Typography>
                      </div>
                    </td>
                    <td className={`max-w-64 ${classes}`}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {committee.designation}
                      </Typography>
                    </td>
                    <td className={`max-w-64 ${classes}`}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {committee.contact}
                      </Typography>
                    </td>
                    {allowEdit && (
                      <>
                        <td className={`${classes} text-center`}>
                          <FormModal
                            title="Update Committee Member"
                            submitButtonTitle="Update"
                            form={{
                              defaultValues: committee,
                              resolver: yupResolver(schema),
                              fieldConfigurations,
                              postUrl: `committees/${committee.id}`,
                              labels,
                              onSubmit: refetch,
                            }}
                            renderButton={({ handleOpen }) => (
                              <Tooltip content="Edit">
                                <IconButton variant="text" onClick={handleOpen}>
                                  <PencilIcon className="h-4 w-4" />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                        </td>
                        <td className={`${classes} text-center`}>
                          <DeleteConfirmationModal
                            title="Delete Committee Member"
                            form={{
                              deleteUrl: `committees/${committee.id}`,
                              onSubmit: refetch,
                            }}
                            renderButton={({ handleOpen }) => (
                              <Tooltip content="Delete">
                                <IconButton variant="text" onClick={handleOpen}>
                                  <TrashIcon className="h-4 w-4" />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </CardBody>
      {!isEmpty(data) && (
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPages}
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm" onClick={prev}>
              Previous
            </Button>
            <Button variant="outlined" size="sm" onClick={next}>
              Next
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
}

const CommitteeInformation = () => {
  const allowEdit = localStorage.getItem("role") === userRole.admin;

  const { data, isLoading, refetch } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/committees`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="mx-auto flex-1 max-w-screen-xl py-12">
      <ListOfCommitteeInformation
        list={data}
        refetch={refetch}
        allowEdit={allowEdit}
      />
    </div>
  );
};

export default CommitteeInformation;
