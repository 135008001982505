import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";
import AuthenticatedMain from "../layout/AuthenticatedMain";

export const ProtectedRoute = ({ roles }) => {
  const { token, role } = useAuth();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // Check if the user is authenticated
  if (roles && !roles.includes(role)) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" />;
  }

  // If authenticated, render the child routes
  return (
    <AuthenticatedMain>
      <Outlet />
    </AuthenticatedMain>
  );
};
