import { useEffect } from "react";
import Loading from "../components/Loading";
import useLogout from "../utils/useLogout";

const Logout = () => {
  const { logout } = useLogout();
  useEffect(() => {
    logout();
  }, [logout]);

  return <Loading />;
};

export default Logout;
