import paginateArray from "paginate-array";
import { useCallback, useMemo, useState } from "react";

const usePaginated = (data, numItemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginateCollection = paginateArray(data, currentPage, numItemsPerPage);
  const next = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage > paginateCollection.totalPages) {
      return;
    }
    setCurrentPage(nextPage);
  }, [currentPage, paginateCollection.totalPages]);

  const prev = useCallback(() => {
    const prevPage = currentPage - 1;
    if (prevPage < 1) {
      return;
    }
    setCurrentPage(prevPage);
  }, [currentPage]);

  return useMemo(
    () => ({
      ...paginateCollection,
      next,
      prev,
    }),
    [next, paginateCollection, prev]
  );
};

export default usePaginated;
