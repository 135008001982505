import React, { useEffect } from "react";

const paymentBtnUrl = "https://checkout.razorpay.com/v1/payment-button.js";
const rzpPaymentFormId = "rzp_payment_form";

const loadButton = async (key) => {
  if (!key) {
    return null;
  }

  const rzpPaymentForm = document.getElementById(rzpPaymentFormId);

  if (!rzpPaymentForm.hasChildNodes()) {
    const script = document.createElement("script");
    script.src = paymentBtnUrl;
    script.async = true;
    script.dataset.payment_button_id = key;
    rzpPaymentForm.appendChild(script);
  }
};

const PaymentButton = ({ rpKey }) => {
  useEffect(() => {
    loadButton(rpKey);
  }, [rpKey]);

  return <form id={rzpPaymentFormId} className="my-4 w-full"></form>;
};

export default PaymentButton;
