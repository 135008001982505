import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/AuthProvider";

const NavButtons = ({ onLogout, fullWidth, className = "" }) => {
  const { token } = useAuth();

  if (token) {
    return (
      <div className="flex items-center gap-x-1">
        <Button
          variant="gradient"
          size="sm"
          onClick={onLogout}
          className={className}
        >
          <span>{"Logout"}</span>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex basis-auto items-center gap-x-1">
      <Link to="/login" className={className}>
        <Button
          fullWidth={fullWidth}
          variant="outlined"
          size="sm"
          className={className}
        >
          <span>{"Groom / Bride Login"}</span>
        </Button>
      </Link>
      <Link to="/registration" className={className}>
        <Button
          fullWidth={fullWidth}
          variant="filled"
          size="sm"
          className={className}
        >
          <span>{"Register"}</span>
        </Button>
      </Link>
    </div>
  );
};

export default NavButtons;
