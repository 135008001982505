import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";
import { ProtectedRoute } from "../pages/ProtectedRoute";
import { useAuth, userRole } from "./AuthProvider";
import Dashboard from "../pages/candidate/Dashboard";
import Profile from "../pages/candidate/Profile";
import HomePage from "../pages/public/HomePage";
import Login from "../pages/candidate/Login";
import Registration from "../pages/candidate/Registration";
import AdminLogin from "../pages/admin/AdminLogin";
import Payment from "../pages/candidate/Payment";
import Main from "../layout/Main";

import AdminCommitteeInformation from "../pages/admin/CommitteeInformation";
import AdminMembershipPolicies from "../pages/admin/MembershipPolicies";
import AdminPreviousMelawas from "../pages/admin/PreviousMelawas";

import CommitteeInformation from "../pages/public/CommitteeInformation";
import MembershipPolicies from "../pages/public/MembershipPolicies";
import PreviousMelawas from "../pages/public/PreviousMelawas";
import ImageGallery from "../pages/public/ImageGallery";
import CandidateProfile from "../pages/candidate/CandidateProfile";
import Logout from "../pages/Logout";

const useIsAuthenticated = () => {
  const { token } = useAuth();

  return async () => {
    if (token) throw redirect("/");
    return null;
  };
};

const Routes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { token } = useAuth();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route element={<Main />}>
          {!token && <Route index element={<HomePage />} />}
          <Route
            path="login"
            loader={async () => await isAuthenticated()}
            element={<Login />}
          />
          <Route
            path="registration"
            loader={async () => await isAuthenticated()}
            element={<Registration />}
          />
          <Route
            path="admin-login"
            loader={async () => await isAuthenticated()}
            element={<AdminLogin />}
          />
          <Route
            path="committee-information"
            element={<CommitteeInformation />}
          />
          <Route path="membership-policies" element={<MembershipPolicies />} />
          <Route path="previous-melawas" element={<PreviousMelawas />} />
          <Route path="gallery" element={<ImageGallery />} />
        </Route>
        <Route
          element={
            <ProtectedRoute roles={[userRole.candidate, userRole.admin]} />
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="candidate/:candidateId" element={<CandidateProfile />} />
        </Route>
        <Route element={<ProtectedRoute roles={[userRole.candidate]} />}>
          <Route path="profile" element={<Profile />} />
          <Route path="payment" element={<Payment />} />
        </Route>
        <Route
          path="admin"
          element={<ProtectedRoute roles={[userRole.admin]} />}
        >
          <Route
            path="committee-information"
            element={<AdminCommitteeInformation />}
          />
          <Route
            path="membership-policies"
            element={<AdminMembershipPolicies />}
          />
          <Route path="previous-melawas" element={<AdminPreviousMelawas />} />
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    )
  );

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
