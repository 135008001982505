import { LazyLoadImage } from "react-lazy-load-image-component";

const Logo = () => (
  <div className="flex items-center justify-center">
    <LazyLoadImage
      style={{ width: 40, height: 40 }}
      src={require("../../assets/logo.webp")}
      alt="Card cap"
    />
    <span className="px-4 font-bold print:hidden">
      {"सुतार (झाडे) समाज महासंघ"}
    </span>
    <span className="px-4 font-bold hidden print:block">
      {process.env.REACT_APP_WEBSITE_NAME}
    </span>
    <LazyLoadImage
      style={{ width: 40, height: 40 }}
      src={require("../../assets/Sutar_Zade_Samaj_Logo.webp")}
      alt="Card cap"
    />
  </div>
);

export default Logo;
