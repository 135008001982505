import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const SubFooter = ({ applicationName }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
      <Typography
        variant="small"
        className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
      >
        &copy; {currentYear}
        <Link to="/" className="px-2">
          {applicationName}
        </Link>
      </Typography>
    </div>
  );
};

export default SubFooter;
