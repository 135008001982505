import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { errorToast } from "./toast";
import { useAuth } from "./AuthProvider";
import useLogout from "./useLogout";

export function useAuthDelete(url, onSuccess, onFailure) {
  const { token } = useAuth();
  return useDelete(url, onSuccess, onFailure, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function useDelete(url, onSuccess, onFailure, config = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { logout } = useLogout();

  const makeRequest = useCallback(
    async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.delete(url, config);
        setData(response.data);
        onSuccess?.(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        errorToast(
          err.response?.data?.message ||
            err.message ||
            err ||
            "Something went wrong, try after some time!"
        );
        onFailure?.(err);
        if (err.status === 401) {
          logout(err.message);
        }
        if (
          (err.code === 500 || err.status === 500) &&
          err?.response?.data?.message === "Expired token"
        ) {
          logout("Session is closed, Logging timeout!");
        }
        if (err.status === 409 && err.message) {
          errorToast(err.message);
        }
        setIsLoading(false);
        setError("An error occurred. Awkward..");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFailure, onSuccess, url]
  );

  return { makeRequest, data, isLoading, error };
}

export function useAuthPost(url, onSuccess, onFailure) {
  const { token } = useAuth();
  return usePost(url, onSuccess, onFailure, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function usePost(
  url,
  onSuccess,
  onFailure,
  config = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { logout } = useLogout();

  const makeRequest = useCallback(
    async (requestData) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(url, requestData, config);
        setData(response.data);
        onSuccess?.(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        errorToast(
          err.response?.data?.message ||
            err.message ||
            err ||
            "Something went wrong, try after some time!"
        );
        onFailure?.(err);
        if (err.status === 401) {
          logout(err.message);
        }
        if (
          (err.code === 500 || err.status === 500) &&
          err?.response?.data?.message === "Expired token"
        ) {
          logout("Session is closed, Logging timeout!");
        }
        if (err.status === 409 && err.message) {
          errorToast(err.message);
        }
        setIsLoading(false);
        setError("An error occurred. Awkward..");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFailure, onSuccess, url]
  );

  return { makeRequest, data, isLoading, error };
}

export function useAuthFetch(url, onSuccess, onFailure) {
  const { token } = useAuth();
  return useFetch(url, onSuccess, onFailure, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function useFetch(
  url,
  onSuccess,
  onFailure,
  config = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const { logout } = useLogout();

  const refetch = useCallback(async () => {
    setLoading("loading...");
    setData(null);
    setError(null);
    try {
      const response = await axios.get(url, config);
      setData(response.data.data);
      onSuccess?.(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      onFailure?.(err);
      if (err.status === 401) {
        logout(err.message);
      }
      if (
        (err.code === 500 || err.status === 500) &&
        err?.response?.data?.message === "Expired token"
      ) {
        logout("Session is closed, Logging timeout!");
      }
      if (err.status === 409 && err.message) {
        errorToast(err.message);
      }
      setLoading(false);
      setError("An error occurred. Awkward..");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, onSuccess, onFailure]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error, refetch };
}
