import React from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { useAuthPost } from "../utils/hooks";
import errorAlert from "../utils/errorAlert";
import Loading from "./Loading";
import useRenderInputs from "../utils/useRenderInputs";

const Form = ({
  submitButtonTitle,
  onClose,
  form: {
    defaultValues,
    resolver,
    fieldConfigurations,
    postUrl,
    labels,
    onSubmit,
  },
}) => {
  const formData = useForm({
    defaultValues,
    resolver,
  });

  const { makeRequest, isLoading } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/${postUrl}`,
    (data) => {
      console.log(data)
      if (data.success) {
        onSubmit();
      } else if (data.status === 409) {
        formData(data.message.errors);
      } else {
        errorAlert(data);
      }
    }
  );

  const renderInput = useRenderInputs(labels, formData);

  return (
    <>
      {isLoading && <Loading />}
      <form
        onSubmit={formData.handleSubmit(makeRequest)}
        id="my-form"
        className="flex flex-col gap-4 md:mt-2"
      >
        {fieldConfigurations.sections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-4 font-medium "
            >
              {section.title}
            </Typography>
            {section.inputGroups.map((inputGroup, igIndex) => (
              <div
                key={igIndex}
                className={`grid md:grid-cols-${inputGroup.length} gap-2`}
              >
                {inputGroup.map((inputConfig, icIndex) => (
                  <React.Fragment key={icIndex}>
                    {renderInput(inputConfig)}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ))}
        <div className="m-auto">
          <Button className="mx-2 bg-primary" onClick={onClose}>
            {"Cancel"}
          </Button>
          {submitButtonTitle && (
            <Button className="mx-2" type="submit">
              {submitButtonTitle}
            </Button>
          )}
        </div>
        {!isEmpty(formData?.formState?.errors) && (
          <p className="flex items-center justify-center gap-1 font-normal text-md text-red-500">
            {
              "Please resolve the all validation errors by providing valid inputs"
            }
          </p>
        )}
      </form>
    </>
  );
};

const FormModal = ({
  renderButton,
  title,
  subTitle,
  submitButtonTitle,
  form,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      {renderButton &&
        renderButton({
          open,
          handleOpen,
        })}
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="p-4"
        dismiss={{ enabled: false }}
      >
        <DialogHeader className="relative m-0 block">
          {title && (
            <Typography variant="h4" color="blue-gray">
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography className="mt-1 font-normal text-gray-600">
              {subTitle}
            </Typography>
          )}
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <XMarkIcon className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6">
          {open && (
            <Form
              onClose={handleOpen}
              submitButtonTitle={submitButtonTitle}
              form={form}
            />
          )}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default FormModal;
