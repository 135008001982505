import { Button } from "@material-tailwind/react";
import React from "react";

const AdditionalButtons = ({ buttons }) => (
  <div className="flex justify-center items-center flex-col md:flex-row">
    {buttons.map(({ href, title }, btnIndex) => (
      <a
        key={btnIndex}
        as="a"
        href={href}
        target="_blank"
        rel="noreferrer"
        className="inline-block"
      >
        <Button variant="text" className="flex items-center gap-2">
          <h4>{title}</h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
            />
          </svg>
        </Button>
      </a>
    ))}
  </div>
);

export default AdditionalButtons;
