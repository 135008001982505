import { useLocation } from "react-router-dom";
import { useAuth, userRole } from "../../utils/AuthProvider";

const useGenerateNavList = () => {
  const { token, role } = useAuth();
  const location = useLocation();

  return [
    {
      title: "Home",
      linkTo: "/",
      isVisible: true,
    },
    {
      title: "Profile",
      linkTo: "/profile",
      isVisible: !!token && role === userRole.candidate,
    },
    {
      title: "About Us",
      items: [
        {
          title: "Committee Information",
          linkTo: "/committee-information",
          isVisible: true,
        },
        {
          title: "Membership Policies",
          linkTo: "/membership-policies",
          isVisible: true,
        },
        {
          title: "Previous Melawas",
          linkTo: "/previous-melawas",
          isVisible: true,
        },
      ],
      isVisible: true,
    },
    {
      title: "Admin",
      items: [
        {
          title: "Committee Information",
          linkTo: "/admin/committee-information",
          isVisible: true,
        },
        {
          title: "Membership Policies",
          linkTo: "/admin/membership-policies",
          isVisible: true,
        },
        {
          title: "Previous Melawas",
          linkTo: "/admin/previous-melawas",
          isVisible: true,
        },
      ],
      isVisible: token && role === userRole.admin,
    },
    {
      title: "Contact Us",
      onClick: () => {
        document
          .getElementById("footer")
          .scrollIntoView({ behavior: "smooth" });
      },
      isVisible: true,
    },
    {
      title: "Gallery",
      linkTo: "/gallery",
      isVisible: location.pathname === "/",
    },
  ];
};

export default useGenerateNavList;
