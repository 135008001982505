import {
  Card,
  Typography,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import {
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import FormModal from "../../components/FormModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getTextAreaInput, getTextInput } from "../candidate/Registration";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import { isEmpty } from "lodash";
import NoDataFound from "../../components/NoDataFound";
import usePaginated from "../../utils/usePaginated";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const TABLE_HEAD = ["आयोजन दिनांक", "स्थळ", "माहिती"];

const defaultValues = {
  date: "",
  place: "",
  information: "",
};

const labels = {
  date: "आयोजन दिनांक*",
  place: "स्थळ*",
  information: "माहिती*",
};

const schema = yup.object().shape({
  date: yup.string().required().label(labels["date"]),
  place: yup.string().required().label(labels["place"]),
  information: yup.string().required().label(labels["information"]),
});

export function ListOfMelawas({ list, refetch }) {
  const { currentPage, totalPages, data, next, prev } = usePaginated(list, 3);

  const fieldConfigurations = {
    sections: [
      {
        title: "",
        inputGroups: [
          [getTextInput("date")],
          [getTextInput("place")],
          [getTextAreaInput("information")],
        ],
      },
    ],
  };

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              {"Previous Melawas (मागील मेळावे)"}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {"Previous Melawas Information"}
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            <FormModal
              title="Add Previous Melawas"
              submitButtonTitle="Add"
              form={{
                defaultValues,
                resolver: yupResolver(schema),
                fieldConfigurations,
                postUrl: "previous_melawas",
                labels,
                onSubmit: refetch,
              }}
              renderButton={({ handleOpen }) => (
                <Button
                  className="flex items-center gap-3"
                  onClick={handleOpen}
                  size="sm"
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
                  {"Add Previous Melawas"}
                </Button>
              )}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-x-scroll md:overflow-x-auto px-0">
        {isEmpty(data) ? (
          <NoDataFound />
        ) : (
          <table className="w-full min-w-max md:table-fixed text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-bold leading-none"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
                <>
                  <th
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    style={{ width: 80 }}
                  ></th>
                  <th
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    style={{ width: 80 }}
                  ></th>
                </>
              </tr>
            </thead>
            <tbody>
              {data.map((prevMelawa, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4 text-wrap max-w-72"
                  : "p-4 border-b border-blue-gray-50 text-wrap max-w-72";

                return (
                  <tr key={index}>
                    <td className={`max-w-64 ${classes}`}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {prevMelawa.date}
                      </Typography>
                    </td>
                    <td className={`max-w-64 ${classes}`}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {prevMelawa.place}
                      </Typography>
                    </td>
                    <td className={`max-w-96 ${classes}`}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {prevMelawa.information}
                      </Typography>
                    </td>
                    <td className={`${classes} text-center`}>
                      <FormModal
                        title="Update Previous Melawas"
                        submitButtonTitle="Update"
                        form={{
                          defaultValues: prevMelawa,
                          resolver: yupResolver(schema),
                          fieldConfigurations,
                          postUrl: `previous_melawas/${prevMelawa.id}`,
                          labels,
                          onSubmit: refetch,
                        }}
                        renderButton={({ handleOpen }) => (
                          <Tooltip content="Edit">
                            <IconButton variant="text" onClick={handleOpen}>
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                    </td>
                    <td className={`${classes} text-center`}>
                      <DeleteConfirmationModal
                        title="Delete Previous Melawas"
                        form={{
                          deleteUrl: `previous_melawas/${prevMelawa.id}`,
                          onSubmit: refetch,
                        }}
                        renderButton={({ handleOpen }) => (
                          <Tooltip content="Delete">
                            <IconButton variant="text" onClick={handleOpen}>
                              <TrashIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </CardBody>
      {!isEmpty(data) && (
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPages}
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm" onClick={prev}>
              Previous
            </Button>
            <Button variant="outlined" size="sm" onClick={next}>
              Next
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
}

const PreviousMelawas = () => {
  const { data, isLoading, refetch } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/previous_melawas`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="mx-auto flex-1 max-w-screen-xl py-12">
      <ListOfMelawas list={data} refetch={refetch} />
    </div>
  );
};

export default PreviousMelawas;
