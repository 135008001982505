import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext();

export const userRole = {
  candidate: "CANDIDATE",
  admin: "ADMIN",
};

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(localStorage.getItem("token"));

  // Function to set the authentication token
  const setToken = (newToken = null, role = userRole.candidate) => {
    setToken_(newToken);
    localStorage.setItem("role", role);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios.defaults.headers.common["Content-Type"] = "application/json";
    }
  }, []);

  useEffect(() => {
    console.log("new Token", token);
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.headers.common["Content-Type"] = "application/json";
      localStorage.setItem("token", token);
    }
    if (token === null) {
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Content-Type"];
      localStorage.removeItem("token");
      localStorage.removeItem("role");
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      role: localStorage.getItem("role"),
    }),
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
