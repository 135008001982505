import React from "react";
import { Navbar, Collapse, IconButton } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import useHandleNav from "./useHandleNav";
import NavButtons from "./NavButtons";
import NavList from "./NavList";
import Logo from "./Logo";
import NavButtonIcon from "./NavButtonIcon";
import useLogout from "../../utils/useLogout";

const Header = () => {
  const [openNav, setOpenNav] = useHandleNav();
  const { logout } = useLogout();

  return (
    <header id="header" className="max-h-[768px]">
      <Navbar className="bg-primary sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4 !border-0 !bg-opacity-100">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Link
            to="/"
            className="text-lg mr-4 cursor-pointer py-1.5 font-medium print:w-full"
          >
            <Logo />
          </Link>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">
              <NavList />
            </div>
            <NavButtons onLogout={logout} className="hidden lg:inline-block" />
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden print:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              <NavButtonIcon isOpen={openNav} />
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>
          <NavList />
          <NavButtons onLogout={logout} fullWidth className="flex-1" />
        </Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
