import { Alert, Typography } from "@material-tailwind/react";

const AlertBox = ({ color, title, message }) => (
  <Alert color={color}>
    <Typography variant="h5" color="white">
      {title}
    </Typography>
    <Typography color="black" className="mt-2 font-normal">
      {message}
    </Typography>
  </Alert>
);

export default AlertBox;
