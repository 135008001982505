import {
  Collapse,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import useHandleNav from "./useHandleNav";

const NavMenu = ({ title, items }) => {
  const [isMenuOpen, setIsMenuOpen] = useHandleNav(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useHandleNav(false);

  const renderItems = items.map(
    ({ title, linkTo, isVisible }, key) =>
      isVisible && (
        <Link to={linkTo} key={key}>
          <MenuItem className="text-secondary">{title}</MenuItem>
        </Link>
      )
  );

  return (
    <>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography
            as="li"
            variant="small"
            className="text-secondary font-bold"
          >
            <ListItem
              className="flex items-center gap-2 p-2 !text-secondary !bg-transparent"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {title}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden rounded-xl lg:block bg-primary">
          {renderItems}
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </>
  );
};

export default NavMenu;
