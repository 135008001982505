import { useCallback, useEffect, useRef, useState } from "react";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import { isEmpty } from "lodash";

const Image = ({ list }) => {
  const lightGalleryRef = useRef(null);
  const containerRef = useRef(null);
  const [galleryContainer, setGalleryContainer] = useState(null);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGalleryRef.current = detail.instance;
      lightGalleryRef.current.openGallery();
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setGalleryContainer(containerRef.current);
    }
  }, []);

  return (
    <section id="gallery" className="px-2 my-6">
      <Typography variant="h4" className="mb-2 text-primary"></Typography>
      <div className="mx-4">
        <Card className="w-full items-center">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
              <div>
                <Typography variant="h5" className="text-primary">
                  {"Image Gallery (प्रतिमा गॅलरी)"}
                </Typography>
              </div>
            </div>
          </CardHeader>
          <CardBody className="w-full">
            <div style={{ height: "80vh" }} ref={containerRef}></div>
            <LightGallery
              container={galleryContainer}
              onInit={onInit}
              plugins={[lgZoom, lgThumbnail]}
              dynamic={true}
              thumbnail={false}
              showMaximizeIcon
              showCloseIcon={false}
              dynamicEl={list.map((item) => ({
                src: item.url,
                subHtml: item.description,
              }))}
            />
          </CardBody>
        </Card>
      </div>
    </section>
  );
};

const ImageGallery = () => {
  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/galleries`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return (
    <>
      <Image list={data} />
    </>
  );
};

export default ImageGallery;
