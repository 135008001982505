import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import SliderImages from "../SliderImages";

const AboutUsImages = ({ images }) => (
  <Card className="w-full max-w-[48rem] h-[95%] items-center md:h-auto flex-row">
    <CardBody>
      <SliderImages
        images={images}
        hideText
        className="h-full md:h-[250px]"
        imageSize={{ height: 300 }}
      />
    </CardBody>
  </Card>
);

export default AboutUsImages;
